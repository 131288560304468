import * as React from 'react';
import { useField } from 'formik';

export interface CheckboxProps extends React.ComponentPropsWithoutRef<'input'> {
  name: string;
  label?: React.ReactNode;
}

export default function Checkbox({
  id,
  name,
  label,
  className,
  ...props
}: CheckboxProps) {
  const inputId = id || React.useId();
  const [{ onChange, ...field }] = useField(name);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = document.querySelector(
      `label[for="${inputId}"].w-checkbox > div.w-checkbox-input`,
    );
    if (!input) return;
    if (e.target.checked) {
      input.classList.add('w--redirected-checked');
    } else {
      input.classList.remove('w--redirected-checked');
    }
    onChange(e);
  };

  return (
    <div className={className}>
      <label htmlFor={inputId} className="w-checkbox wp-checkbox privacypolicy">
        <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox privacypolicy" />
        <input
          hidden
          type="checkbox"
          id={inputId}
          style={{ display: 'none' }}
          onChange={handleChange}
          {...field}
          {...props}
        />
        {label && <span className="caption w-form-label">{label}</span>}
      </label>
    </div>
  );
}
