import * as React from 'react';

import clsx from 'clsx';
import emailjs from '@emailjs/browser';
import { Link } from 'gatsby';

import * as yup from 'yup';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';

import * as RDD from 'react-device-detect';
import ReCAPTCHA from 'react-google-recaptcha';

import Checkbox from './checkbox';
import TextInput from './text-input';
import TextareaInput from './textarea-input';

import { useIPAddress, useSearchParams } from '../../hooks';
import { usePageProps } from '../../contexts';

const BLOCKED_DOMAINS = [
  'gmail',
  'outlook',
  'hotmail',
  'live',
  'msn',
  'yahoo',
  'icloud',
  // @NOTE: these domains are commnonly used to bypass the spam filters
  'email',
  'business',
  'buisness',
  'domain',
  'corporate',
];

const isBlockedDomain = (email: string) => {
  const domain = email.split('@')?.[1]?.toLowerCase() || '';
  const rootDomain = domain.split('.')?.[0] || '';
  return BLOCKED_DOMAINS.includes(rootDomain);
};

interface ContactFormValues {
  name: string;
  email: string;
  phone: string;
  about: string;
  privacyPolicy: boolean;
}

const contactFormValidationSchema = yup.object().shape({
  name: yup
    .string()
    .max(256, "The name can't have more than 256 characters")
    .required('The name is required'),
  email: yup
    .string()
    .email('The email must have a valid format')
    .test(
      'blocked-domain',
      'Only business domains are allowed',
      (value) => !value || !isBlockedDomain(value),
    )
    .matches(
      /.+\.[a-zA-Z]+$/,
      'The email must end with a valid domain suffix (e.g., .com, .eu)',
    )
    .max(256, "The email can't have more than 256 characters")
    .required('The email is required'),
  phone: yup
    .string()
    .max(256, "The phone can't have more than 256 characters")
    .optional(),
  about: yup
    .string()
    .min(50, "The message can't have less than 50 characters")
    .max(256, "The message can't have more than 256 characters")
    .required('The message is required'),
  privacyPolicy: yup.boolean().isTrue().required(),
});

const CONTACT_FORM_DEFAULT_VALUES: ContactFormValues = {
  name: '',
  email: '',
  phone: '',
  about: '',
  privacyPolicy: false,
};

export interface ContactFormProps
  extends React.ComponentPropsWithoutRef<'div'> {
  label?: React.ReactNode;
}

export default function ContactForm({
  label,
  className,
  ...props
}: ContactFormProps) {
  const ip = useIPAddress();
  const page = usePageProps();
  const params = useSearchParams(page?.location ?? null);

  const recaptchaRef = React.useRef<ReCAPTCHA | null>(null);
  const formRef = React.useRef<HTMLFormElement | null>(null);

  const hiddenInputs = React.useMemo(
    () => [
      { name: 'origin', value: 'Your Digital Partner' },
      { name: 'page', value: 'Your Digital Partner' },
      {
        name: 'device',
        value:
          (RDD.isMobile && 'Mobile') ||
          (RDD.isDesktop && 'Desktop') ||
          'Unknown',
      },
      { name: 'user_agent', value: RDD.getUA },
      { name: 'ip', value: ip },
      { name: 'utm_source', value: params.get('utm_source') ?? '' },
      { name: 'utm_medium', value: params.get('utm_medium') ?? '' },
      { name: 'utm_campaign', value: params.get('utm_campaign') ?? '' },
      { name: 'utm_content', value: params.get('utm_content') ?? '' },
      { name: 'utm_term', value: params.get('utm_term') ?? '' },
      { name: 'utm_ad', value: params.get('utm_ad') ?? '' },
      {
        name: 'utm_ad_campaign_id',
        value: params.get('utm_ad_campaign_id') ?? '',
      },
      { name: 'adgroup', value: params.get('adgroup') ?? '' },
      { name: 'cq_cmp', value: params.get('cq_cmp') ?? '' },
      { name: 'gad_source', value: params.get('gad_source') ?? '' },
      { name: 'gclid', value: params.get('gclid') ?? '' },
    ],
    [RDD, ip, params],
  );

  const handleSubmit = async (
    values: ContactFormValues,
    helpers: FormikHelpers<ContactFormValues>,
  ) => {
    if (formRef.current) {
      try {
        const response = await fetch(
          `https://dns.google/resolve?name=${values.email.split('@')[1]}&type=MX`,
        );
        const data = await response.json();
        if ('Answer' in data && data['Answer'].length > 0) {
          await emailjs.sendForm(
            'mosano-ses',
            'landing-pages',
            formRef.current,
          );

          // @ts-ignore Fire a custom event after successful form submission
          window.dataLayer.push({
            event: 'formSubmissionSuccess',
            eventCategory: 'form',
            eventAction: 'submit',
            eventLabel: 'Your Digital Partner Landing Page',
            eventValue: 1,
          });
          window.location.assign('https://mosano.eu/thanks?yourdigitalparnter');
        } else {
          helpers.setFieldError('email', 'The email must have a valid domain');
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <div
      id="contact-form"
      data-w-id="07875213-2ee7-f39f-5578-f1faecdf2509"
      className={clsx('wp-form', className)}
      {...props}
    >
      {label && (
        <h3 className="h2 center" style={{ marginBottom: 24 }}>
          {label}
        </h3>
      )}
      <div
        id="wf-form-content"
        data-name="Contact Form"
        className="form w-form"
        style={{ marginBottom: 0 }}
      >
        <Formik
          initialValues={CONTACT_FORM_DEFAULT_VALUES}
          validationSchema={contactFormValidationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form
              ref={formRef}
              id="form_landingPage"
              name="wf-form-Contact-Form"
              data-name="Contact Form"
              className="form-text w-clearfix"
              aria-disabled={isSubmitting}
            >
              {hiddenInputs.map((input, index) => (
                <input
                  key={`hidden-input#${index}`}
                  hidden
                  type="text"
                  id={input.name}
                  name={input.name}
                  data-name={input.name}
                  value={input.value}
                  style={{ display: 'none' }}
                />
              ))}
              <TextInput
                required
                id="name"
                name="name"
                label="Name"
                data-name="name"
                autoComplete="name"
                className="form-name"
                readOnly={isSubmitting}
              />
              <div className="wp-form-contacts">
                <TextInput
                  required
                  id="email"
                  name="email"
                  label="Email"
                  data-name="email"
                  autoComplete="email"
                  className="form-email"
                  readOnly={isSubmitting}
                />
                <TextInput
                  id="phone"
                  name="phone"
                  label="Phone (optional)"
                  data-name="phone"
                  autoComplete="tel"
                  className="form-phone"
                  readOnly={isSubmitting}
                />
              </div>
              <TextareaInput
                required
                id="about"
                name="about"
                label="Message"
                data-name="about"
                className="form-about"
                readOnly={isSubmitting}
              />
              <Checkbox
                required
                id="Privacy-Policy"
                name="privacyPolicy"
                data-name="Privacy Policy"
                className="form-privacy"
                readOnly={isSubmitting}
                label={
                  <React.Fragment>
                    By submitting this form, I consent to Mosano collecting my
                    information for marketing and commercial purposes in
                    accordance with the{' '}
                    <Link to="/privacy-policy">Privacy Policy</Link>. I
                    understand that I can unsubscribe or withdraw my consent at
                    any time.
                  </React.Fragment>
                }
              />
              <div className="submit-button-row">
                {process.env.GATSBY_RECAPTCHA_SITE_KEY && (
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={process.env.GATSBY_RECAPTCHA_SITE_KEY}
                  />
                )}
                <button
                  type="submit"
                  data-wait="Please wait..."
                  id="tracking-button"
                  className="button form w-button submit-button"
                  style={{ marginLeft: 'auto' }}
                  disabled={isSubmitting}
                >
                  Send Message
                </button>
              </div>
              <FormErrors />
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

interface FormErrorsProps extends React.ComponentPropsWithoutRef<'div'> {}

function FormErrors({ className, style, ...props }: FormErrorsProps) {
  const { errors } = useFormikContext<ContactFormValues>();
  const displayErrors = Object.keys(errors).filter(
    (key) => key !== 'privacyPolicy',
  );

  if (displayErrors.length === 0) return null;
  return (
    <div
      className={clsx('error-message w-form-fail', className)}
      style={{ display: 'block', ...style }}
      {...props}
    >
      <div className="text-block-8" style={{ textAlign: 'unset' }}>
        <ul style={{ marginBottom: 0, paddingLeft: '1.5rem' }}>
          {displayErrors.map((key) => (
            <li key={`error#${key}`}>
              {errors[key as keyof ContactFormValues]}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
