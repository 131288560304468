import clsx from 'clsx';
import * as React from 'react';

export interface ErrorMessageProps
  extends React.ComponentPropsWithoutRef<'p'> {}

export default function ErrorMessage({
  className,
  children,
  ...props
}: ErrorMessageProps) {
  return (
    <p className={clsx('form-error-message', className)} {...props}>
      {children}
    </p>
  );
}
