import * as React from 'react';

import clsx from 'clsx';
import { useField } from 'formik';

import ErrorMessage from './error-message';

export interface TextInputProps
  extends React.ComponentPropsWithoutRef<'input'> {
  name: string;
  label?: React.ReactNode;
}

export default function TextInput({
  id,
  name,
  label,
  className,
  ...props
}: TextInputProps) {
  const inputId = id || React.useId();
  const [field, meta] = useField(name);
  const hasError = meta.touched && !!meta.error;

  return (
    <div className={className}>
      {label && (
        <label htmlFor={inputId} className="form-label">
          {label}
        </label>
      )}
      <input
        type="text"
        id={inputId}
        className={clsx('form-field w-input', hasError && 'form-field-error')}
        {...field}
        {...props}
      />
      {hasError && <ErrorMessage>{meta.error}</ErrorMessage>}
    </div>
  );
}
